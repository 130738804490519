/**
 * familydoctor.com.cn base js for pc
 * Author: deiphi@qq.com
 */

if (window.FD) { throw new Error('FD对象已存在'); }

var FD = {
    util: {
        /**
         * 判断是否是整数 
         * @param	{string || number}		str		字符串或数字
         * @return	{boolean}
         * eg
            FD.util.isInt(3.14);
         */
        isInt: function (str) {
            return /^-?\d+$/.test(str);
        },

        /**
         * 判断是否是数字(整数或浮点数)
         * @param	{string || number}		str		字符串或数字
         * @return	{boolean}
         * eg
            FD.util.isNumber(3.14);
         */
        isNumber: function (str) {
            return /^(-?\d+)(\.\d+)?$/i.test(str);
        },

        /**
         * 判断是否是手机(只做简单判断，以1开头后面是10位的数字)
         * @param	{string || number}		str		字符串或数字
         * @return	{boolean}
         * eg
            FD.util.isMobile(13800138000);
         */
        isMobile: function (str) {
            return /^1\d{10}$/.test(str);
        },

        /**
         * 判断是否是身份证号码
         * @param	{string || number}		str		字符串或数字
         * @return	{boolean}
         * eg
            FD.util.isIdCard(123456789012345);
         */
        isIdCard: function (str) {
            return /(^\d{15}$)|(^\d{17}([0-9]|X)$)/i.test(str);
        },

        /**
         * 判断email
         * @param	{string}	str		email地址
         * @return	{boolean}
         * eg
         FD.util.isEmail('abc@gmail.com');
        */
        isEmail: function (str) {
            var myReg = /^[-_A-Za-z0-9\.]+@([_A-Za-z0-9]+\.)+[A-Za-z0-9]{2,3}$/;
            if (myReg.test(str)) { return true; }
            return false;
        },

        /**
         * 为手机号做掩码
         * @param	{string || number}		str		字符串或数字
         * @return	{string}
         * eg
            FD.util.maskMobile(13800138000); //返回：138******00
         */
        maskMobile: function (mobile) {
            if (!mobile) { return ''; }
            if (mobile.length !== 11) { return mobile; }
            mobile = mobile.substring(0, 3) + '******' + mobile.substring(9);
            return mobile;
        },

        /**
         * 删除字符串两端空格
         * @param	{string}		str		字符串
         * @return	{string}
         * eg
            var newStr = FD.util.trim(str);
         */
        trim: function (str) {
            if (str === null || str === undefined || str === '') { return ''; }

            if (String.prototype.trim) {
                return str.trim();
            }

            return str.replace(/^\s*((?:[\S\s]*\S)?)\s*$/, '$1');
        },

        /**
         * 数组去重
         * @param	{Array}     arr     原数组
         * @return	{Array}             去重后的数组
         * eg
            var arr = [1, '1', 1, '1', 'a', 'a', 'b'];
            FD.util.unique(arr);        // 返回：[1, '1', 'a', 'b']
        */
        unique: function (arr) {
            var r = [];

            for (var i = 0, l = arr.length; i < l; i++) {
                for (var j = i + 1; j < l; j++) {
                    if (arr[i] === arr[j]) { j = ++i; }
                }

                r.push(arr[i]);
            }

            return r;
        },

        /**
         * 获取url参数
         * @param	{string}	url		链接地址 
         * @param	{string}	name		key
         * @return	{string}	value
         * eg
         var val = FD.util.getQueryString(location.href, 'id');
        */
        getQueryString: function (url, name) {
            var reg = new RegExp('(^|\\?|&)' + name + '=([^&]*)(\\s|&|$)', 'i');
            var match = url.match(reg);
            if (match) {
                return decodeURIComponent(match[2].replace(/\+/g, ' '));
            }
            return '';
        },

        /**
          * 格式化参数
          * @param	{object}	data	参数对象
          * @param	{boolean}	noCache	是否缓存(如果不缓存将会增加一个随机参数)
          * @return	{string}	queryString
          * eg
          FD.util.formatParams({name: 'tom', age: 25});
         */
        formatParams: function (data, noCache) {
            var arr = [];

            if (noCache === void 0) { noCache = false; }

            for (var name in data) {
                arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
            }

            noCache && arr.push('_random_=' + new Date().getTime());

            return arr.join('&');
        },

        /**
         * 把文本内容转换成html
         * @param   {string}    str     要转换的字符串，通常是从textarea控件获取得到
         * @return  {string}    格式化好的html字符串
         */
        htmlEncode: function (str) {
            str = str.replace(/\r\n/g, '<br/>');
            str = str.replace(/\n/g, '<br/>');
            str = str.replace(/\r/g, '<br/>');
            str = str.replace(/\s/g, '&nbsp;');
            return str;
        },

        /**
         * 获取一个随机数
         * @param  {number}    n      最小值
         * @param  {number}    m      最大值
         * @return {number}    随机数
         */
        getRandom: function (n, m) {
            if (m === undefined) {
                m = n;
                n = 0;
            }

            return Math.round(Math.random() * (m - n) + n);
        }
    },

    /**
	 * 异步加载图片
	 * @param	{string}	url			链接地址 
	 * @param	{function}	callback	回调函数
	 * @return	null
	 * eg
	   FD.loadImage('https://www.baidu.com/img/bdlogo.png', function(img){
		   console.log('image width: '+ img.width + ' image height: '+ image.height);
	   });
	*/
    loadImage: function (url, callback) {
        var img = new Image();
        img.src = url;

        if (img.complete) {
            callback(img);
            return;
        }

        img.onload = function () {
            callback(img);
        };
    },

    /**
     * 检测浏览器是否支持Fixed
     * eg
       FD.supportFixed();
     */
    supportFixed: function () {
        var check = function () {
            var outer = document.createElement('div'),
                inner = document.createElement('div'),
                result = true;

            outer.style.position = 'absolute';
            outer.style.top = '200px';
            inner.style.position = 'fixed';
            inner.style.top = '100px';
            outer.appendChild(inner);
            document.body.appendChild(outer);

            if (inner.getBoundingClientRect &&
                inner.getBoundingClientRect().top == outer.getBoundingClientRect().top) {
                result = false;
            }

            document.body.removeChild(outer);
            FD.util.supportFixed = function () { return result; };
            return result;
        };

        return check();
    },

    /**
     * 检测浏览器是否支持Transform
     * eg
       FD.supportTransform();
     */
    supportTransform: function () {
        var check = function () {
            var result = true;

            if (/msie/i.test(navigator.userAgent) && !('-ms-transform' in document.createElement('dummy').style)) {
                result = false;
            }

            FD.util.supportTransform = function () { return result; };
            return result;
        };

        return check();
    },

    /**
	 * cookie操作
	 * @param	{string}	sMainName	cookie名称
	 * @param	{string}	sSubName	cookie名称(选填)
	 * @param	{string}	返回的值
	 * @return	null
	 * eg
	   var mycookie = FD.cookie.get('cookieName');
       FD.cookie.set('mykey', 'myvalue');
	*/
    cookie: {
        get: function (sMainName, sSubName) {
            var re = new RegExp((sSubName ? sMainName + '=(?:.*?&)*?' + sSubName + '=([^&;$]*)' : sMainName + '=([^;$]*)'), 'i');
            var match = re.exec(decodeURIComponent(document.cookie));
            return match ? match[1] : '';
        },
        set: function (cookieName, cookieValue, minutes, path, domain, secure) {
            var expires = new Date();
            expires.setTime(expires.getTime() + (minutes * 60 * 1000));
            document.cookie = encodeURIComponent(cookieName) + '=' + encodeURIComponent(cookieValue) +
                (expires ? '; expires=' + expires.toGMTString() : '') +
                (path ? '; path=' + path : '/') + 
                (domain ? '; domain=' + domain : '') +
                (secure ? '; secure' : '');
        }
    },

    /**
     * 百度分享
     * @param   {Element}       elem            放置分享内容的DOM节点
     * @param	{object}		options		    配置参数
     * @param	{array}			options.keys	要显示分享的数组
     * @param	{number}		options.size	图标的尺寸(16px or 32px)
     * eg
       var share = document.getElementById('selector');
       FD.share.init(share, {keys: ['sina', 'tencent', 'wechat'], size: 32});
    */
    share: {
        init: function (elem, options) {
            if (elem.tagName !== 'DIV') { console.log('父标签必须是div元素'); }
            elem.innerHTML = this.getHtml(options);
            this.load();
        },
        _items: {
            sina: '<a href="#" class="bds_tsina" data-cmd="tsina" title="分享到新浪微博"></a>',
            tencent: '<a href="#" class="bds_tqq" data-cmd="tqq" title="分享到腾讯微博"></a>',
            wechat: '<a href="#" class="bds_weixin" data-cmd="weixin" title="分享到微信"></a>',
            qzone: '<a href="#" class="bds_qzone" data-cmd="qzone" title="分享到QQ空间"></a>',
            renren: '<a href="#" class="bds_renren" data-cmd="renren" title="分享到人人网"></a>',
            douban: '<a href="#" class="bds_douban" data-cmd="douban" title="分享到豆瓣网"></a>',
            friend: '<a href="#" class="bds_tqf" data-cmd="tqf" title="分享到腾讯朋友"></a>',
            tieba: '<a href="#" class="bds_tieba" data-cmd="tieba" title="分享到百度贴吧"></a>',
            qq: '<a href="#" class="bds_sqq" data-cmd="sqq" title="分享到QQ好友"></a>',
            mshare: '<a href="#" class="bds_mshare" data-cmd="mshare" title="分享到一键分享"></a>'
        },
        getHtml: function (options) {
            var defaults = {
                keys: options && options.keys || ['sina', 'tencent', 'wechat', 'qzone', 'renren', 'douban', 'friend', 'tieba', 'qq', 'mshare'],
                size: 16
            };

            var buffer = '';

            FD.extend(defaults, options || {});

            buffer = '<div class="bdsharebuttonbox" data-tag="share_' + defaults.size + '">';

            if (options && options.showMore !== false) {
                buffer += '<a href="#" class="bds_more" data-cmd="more"></a>';
            }

            for (var i = 0, len = defaults.keys.length; i < len; i++) {
                buffer += this._items[defaults.keys[i]];
            }

            buffer += '</div>';
            return buffer;
        },
        load: function () {
            // window._bd_share_config = { 'common': { 'bdSnsKey': {}, 'bdText': '', 'bdMini': '2', 'bdMiniList': false, 'bdPic': '', 'bdStyle': '0' }, 'share': [{ 'tag': 'share_16', 'bdSize': 16 }, { 'tag': 'share_32', 'bdSize': 32 }] }; with (document) 0[(getElementsByTagName('head')[0] || body).appendChild(createElement('script')).src = '//bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js?cdnversion=' + ~(-new Date() / 36e5)];
            
            window._bd_share_config = {
                common: {
                    bdSnsKey: {},
                    bdText: '',
                    bdMini: '2',
                    bdMiniList: false,
                    bdPic: '',
                    bdStyle: '0',
                },
                share: [
                    { tag: 'share_16', bdSize: 16 },
                    { tag: 'share_32', bdSize: 32 },
                ],
            };

            var head = document.getElementsByTagName('head')[0] || document.body;
            var script = document.createElement('script');
            script.src = '//bdimg.share.baidu.com/static/api/js/share.js?v=89860593.js?cdnversion=' + ~(-new Date() / 36e5);
            head.appendChild(script);
        }
    },

    /**
     * 上传文件（基于iframe上传）
     * @param	{string}		url			服务端处理上传的url
     * @param	{node}			fileEle		input[type=file]控件
     * @param	{function}		before		上传之前回调
     * @param	{function}		callback	上传之后回调
     * eg
        file.onchange = new FD.Uploader({
            url: url,
            fileEle: file,
            before: function(){
                console.log('uploading...');
                //return 'abort'; //如果返回abort则停止上传
            },
            callback: function(data){
                console.log(data);
            }
        });
    */
    Uploader: function (options) {
        options.before = options.before || function () { };

        var upload = function () {
            var iframe = (/MSIE (6|7|8)/).test(navigator.userAgent) ? document.createElement('<iframe name="iframe">') : document.createElement('iframe');
            var form = document.createElement('form');
            var hidden = document.createElement('input');
            var parent = options.fileEle.parentNode;
            var sibling = options.fileEle.nextSibling;
            var cbName = options.callbackName || 'cb_' + Math.random().toString(36).substring(2);

            if (!options.fileEle.value || options.before() === 'abort') { return; }

            iframe.name = 'iframe';
            hidden.type = 'hidden';
            hidden.name = 'callback';
            hidden.value = cbName;
            form.enctype = 'multipart/form-data';
            form.encoding = 'multipart/form-data'; //hack ie6/7/8
            form.method = 'post';
            form.target = 'iframe';
            form.action = options.url;
            form.style.display = 'none';
            form.appendChild(iframe);
            form.appendChild(options.fileEle);
            form.appendChild(hidden);
            document.body.appendChild(form);
            form.submit();

            window[cbName] = function (data) {
                if (sibling) {
                    parent.insertBefore(options.fileEle, sibling);
                } else {
                    parent.appendChild(options.fileEle);
                }

                options.fileEle.value = '';
                document.body.removeChild(form);
                options.callback(data);
            };
        };

        return upload;
    },

    /**
     * 类似原生的confirm
     * @param   {object}    options     配置参数
     * @param   {string}    msg         信息内容
     * @param   {function}  onSubmit    点击确定回调
     * @param   {function}  onCancel    点击取消回调
     * @param   {boolean}   showCancel  是否显示取消按钮
       eg
       FD.confirm({
           msg: '确定要删除吗？',
           onSubmit: function(){ console.log('confirm'); },
           onCancel: function(){ console.log('cancel'); }
           }
       });
     */
    confirm: function (options) {
        options = options || {};
        options.msg = options.msg || '提示';
        options.onSubmit = options.onSubmit || function () { };
        options.onCancel = options.onCancel || function () { };
        options.showCancel = options.showCancel === false ? false : true;

        var frag = document.createDocumentFragment();
        var $tips = document.createElement('div');
        var $btnWrap = document.createElement('div');
        var $confirm = document.createElement('input');
        var $cancel = null;

        $tips.className = 'fd-dia-tips';
        $tips.innerHTML = '<h1>' + options.msg + '</h1>';
        $btnWrap.className = 'fd-btn-wrap';
        $confirm.type = 'button';
        $confirm.value = '确定';
        $confirm.className = 'fd-btn fd-btn-primary';
        frag.appendChild($tips);
        $btnWrap.appendChild($confirm);

        if (options.showCancel) {
            $cancel = document.createElement('input');
            $cancel.type = 'button';
            $cancel.value = '取消';
            $cancel.className = 'fd-btn fd-btn-cancel';
            $btnWrap.appendChild($cancel);
        }

        frag.appendChild($btnWrap);

        var dialog = new FD.Dialog({
            title: options.title,
            width: 300,
            showClose: false,
            showTitle: false,
            content: frag,
            onOpened: function () {
                //确定
                $confirm.onclick = function () {
                    dialog.close();
                    options.onSubmit();
                };

                //取消
                if ($cancel) {
                    $cancel.onclick = function () {
                        dialog.close();
                        options.onCancel();
                    };
                }
            }
        });
    },

    /**
     * 弹出一条提示信息
     * @param   {string}    content         信息内容
     * @param   {string}    width           窗口宽度
     * @param   {number}    sec             自动关闭的时间，以秒为单位, 若不设置则不会自动关闭
     * @param   {function}  onCloseAfter    回调函数
     * @return  {object}    返回一个FD.Toast实例
       eg
       new FD.Toast({content: '正在上传，请稍后...', width: '150px'});
     */
    Toast: function (options) {
        var defaults = {
            width: '120px',
            maxWidth: '100%',
            minWidth: '30px',
            content: 'Hi',
            showTitle: false,
            showClose: false,
            showMask: false,
            onCloseAfter: function () { }
        };

        FD.extend(defaults, options);

        var dialog = new FD.Dialog(defaults);

        if (options.sec && !options.showClose) {
            setTimeout(function () {
                dialog.close();
            }, options.sec * 1000);
        }
        else if (options.sec && options.showClose) {
            throw new Error('秒数和关闭按钮不能同时使用');
        }

        return dialog;
    },

    /**
     * 以弹窗的方式查看图片
     * @param   {string}    url     图片地址
       eg
       FD.viewPhoto('photourl.jpg');
     */
    viewPhoto: function (url) {
        var box = document.createElement('div');

        box.className = 'view-photo';
        box.innerHTML = 'loading...';

        return new FD.Dialog({
            showTitle: false,
            content: box,
            onOpened: function () {
                FD.loadImage(url, function (img) {
                    var image = document.createElement('img');

                    image.src = url;

                    if (img.width > img.height) {
                        if (img.width > box.clientWidth) {
                            image.style.width = box.clientWidth + 'px';
                        }
                    } else {
                        if (img.height > box.clientHeight) {
                            var screenHeight = document.documentElement.clientHeight || document.body.clientHeight;

                            if (img.height > screenHeight) {
                                image.style.height = screenHeight * 0.8 + 'px';
                            }
                        }
                    }

                    box.innerHTML = '';
                    box.appendChild(image);
                });
            }
        });
    },

    /**
     * 为不支持placeholder的浏览器添加placeholder功能
     * @param   {node}      context     应用范围，默认为document
       eg
       FD.placeholder();
     */
    placeholder: function (context) {
        var input = document.createElement('input');

        if ('placeholder' in input) { return; }
        context = context || document;

        var inputs = context.getElementsByTagName('input');
        var textareas = context.getElementsByTagName('textarea');
        var textareaCount = textareas.length;
        var items = [];

        for (var i = 0, len = inputs.length; i < len; i++) {
            if (inputs[i].type === 'text' || inputs[i].type === 'password') {
                items.push(inputs[i]);
            }
        }

        for (var j = 0, count = items.length; j < count; j++) {
            bindEvent(cloneNode(items[j]));
        }

        for (var k = 0; k < textareaCount; k++) {
            (function (textarea) {
                setTimeout(function () {
                    bindEvent(cloneNode(textarea));
                }, 30);
            })(textareas[k]);
        }

        function bindEvent(newNode) {
            newNode.onfocus = function () {
                this.style.display = 'none';
                this.target.style.display = '';
                this.target.value = '';
                this.target.focus();
            };

            //原节点
            newNode.target.onblur = function () {
                if (this.value === '') {
                    this.style.display = 'none';
                    newNode.style.display = '';
                    newNode.style.color = 'gray';
                }
            };
        }

        function cloneNode(oldNode) {
            var isInput = oldNode.tagName === 'INPUT';
            var newNode = isInput ? document.createElement('input') : document.createElement('textarea');
            var placeholder = oldNode.getAttribute('placeHolder') || '';

            if (isInput) { newNode.type = 'text'; }
            newNode.setAttribute('placeHolder', placeholder);

            if (oldNode.size) { newNode.size = oldNode.size; }
            if (oldNode.className) { newNode.className = oldNode.className; }

            if (oldNode.value === '') {
                newNode.style.display = '';
                oldNode.style.display = 'none';
            } else {
                newNode.style.display = 'none';
            }

            newNode.value = placeholder;
            newNode.style.color = 'gray';
            newNode.target = oldNode;
            oldNode.parentNode.insertBefore(newNode, oldNode);
            return newNode;
        }
    },

    /**
     * 类似jquery的offset方法，暂时不支持fixed的元素
     * @param   {node}      elem    DOM节点
       eg
       FD.getOffset(document.getElementById(selectorID));
     */
    getOffset: function (elem) {
        var offset = { left: 0, top: 0 };

        while (elem.offsetParent) {
            offset.left += elem.offsetLeft;
            offset.top += elem.offsetTop;

            elem = elem.offsetParent;
        }

        return offset;
    },

    /**
     * 获取jsonp数据
     * @param   {string}    url         js地址
     * @param   {function}  callback    回调函数
       eg
       FD.getScript(url, function(result){ console.log(result); })
     */
    getJsonp: function (url, callback) {
        var fn = 'callback' + new Date().getTime();

        url = url.indexOf('?') !== -1 ? (url + '&callback=' + fn) : (url + '?callback=' + fn);

        FD.getScript(url);

        window[fn] = function (data) {
            try {
                if (callback && typeof callback === 'function') {
                    callback(data);
                }
            } catch (e) {
                console.log(e);
            } finally {
                window[fn] = null;
            }
        };
    },

    /**
     * 异步加载js
     * @param   {string}    url         js地址
     * @param   {function}  callback    回调函数
       eg
       FD.getScript(url, function(){ console.log('loaded'); })
     */
    getScript: function (url, callback) {
        if (!url) {
            throw new Error('url not allow null or empty');
        }

        var head = document.getElementsByTagName('head')[0];
        var js = document.createElement('script');
        var isLoaded = false;

        js.type = 'text/javascript';
        js.src = url;
        head.appendChild(js);

        if (typeof callback !== 'function') { return; }

        js.onreadystatechange = function () {
            if (isLoaded) { return; }

            if (js.readyState === 'loaded' || js.readyState === 'complete') {
                isLoaded = true;
                callback();
            }
        };

        js.onload = function () {
            if (isLoaded) { return; }
            isLoaded = true;
            callback();
        };
    },

    /**
   * 对象拷贝，类似jquery的$.extend()方法
   * @param	{object}	a	原对象
   * @param	{object}	b	新对象
   * @return	{object}	合并后的对象
   * eg
     var newObj = FD.extend(obj1, obj2);
  */
    extend: function (a, b) {
        for (var i in b) {
            if (typeof b[i] === 'object' && b[i] !== null) {
                a[i] = a[i] || {};
                FD.extend(a[i], b[i]);
            } 
            else {
                a[i] = b[i];
            }
        }
        return a;
    },

    /**
     * 函数节流方法
     * @param   {Function}  fn       延时调用函数
     * @param   {Number}    delay    延迟多长时间
     * @param   {Number}    atleast  至少多长时间触发一次
     * @return  {Function}  延迟执行的方法
     * eg
       window.onscroll = FD.throttle(function(){}, 300, 500);
     */
    throttle: function (fn, delay, atleast) {
        var timer = null;
        var previous = null;

        return function () {
            var now = +new Date();

            if (!previous) { previous = now; }

            if (now - previous > atleast) {
                fn();
                previous = now;
            } else {
                clearTimeout(timer);
                timer = setTimeout(function () {
                    fn();
                }, delay);
            }
        };
    },

    initTabs: function (selector, eventType) {
        selector = selector || '.js-tab-wrap';
        eventType = eventType || 'click';

        var wraps = $(selector);
        var init = function (wrap) {
            var tabs, items;
            var layer = wrap.attr('data-layer');

            if (layer) {
                tabs = wrap.find('.js-tabs[data-layer="'+ layer +'"] a');
                items = wrap.find('.js-tab-item[data-layer="'+ layer +'"]');
            } 
            else {
                tabs = wrap.find('.js-tabs a');
                items = wrap.find('.js-tab-item');
            }
            
            var curTab = tabs.filter('.active');
            var curItem = items.not('.hide');

            curTab = curTab.length ? curTab : tabs.filter('.on');

            tabs.each(function (i) {
                $(this).data('index', i);
            });

            items.each(function (i) {
                $(this).data('index', i);
            });

            tabs.on(eventType, function () {
                var i = parseInt($(this).data('index'));

                curTab.length && curTab.removeClass('active').removeClass('on');
                $(this).addClass('active').addClass('on');
                curTab = $(this);
                curItem.addClass('hide');
                curItem = items.eq(i);
                curItem.removeClass('hide');
            });
        };

        wraps.each(function () {
            init($(this));
        });
    }
};

//dialog plus
(function (win, doc) {
    var $html = null;
    var $body = null;

    function Dialog(settings) {
        this.defaults = {
            maxWidth: '80%',
            minWidth: '300px',
            width: '80%',
            height: 'auto',
            title: '提示',
            content: '', //弹窗内容，可以是dom节点也可以是字符串
            url: '',    //iframe url
            zIndex: 1100,
            autoShow: true,
            maskClick: false,
            showTitle: true,
            showClose: true,
            showMask: true,
            boxClassName: 'dialog-box',
            clsClassName: 'dia-close',
            onInit: function () { },
            onOpened: function () { },
            onCloseBefore: function () { },
            onCloseAfter: function () { }
        };
        this.defaults = extend(this.defaults, settings || {});
        this.mask = null;
        this.box = null;
        this._init();
    }

    Dialog.prototype.level = 0;

    Dialog.prototype._init = function () {
        var that = this;
        var maxWidth = this.defaults.maxWidth;
        var minWidth = this.defaults.minWidth;
        var width = this.defaults.width;
        var height = this.defaults.height;
        var reg = /\%|auto/i;

        Dialog.prototype.level++;
        this.mask = doc.createElement('div');
        this.box = doc.createElement('div');

        this.mask.className = 'dialog-mask';
        this.mask.style.zIndex = this.defaults.zIndex + this.level;
        this.box.className = this.defaults.boxClassName;
        this.box.style.zIndex = this.defaults.zIndex + this.level;
        this.box.style.maxWidth = reg.test(maxWidth) ? maxWidth : parseInt(maxWidth) + 'px';
        this.box.style.minWidth = reg.test(minWidth) ? minWidth : parseInt(minWidth) + 'px';
        this.box.style.width = reg.test(width) ? width : parseInt(width) + 'px';
        this.box.style.height = reg.test(height) ? height : parseInt(height) + 'px';

        this.sbj = doc.createElement('div');
        this.sbj.className = 'dia-sbj';
        this.title = doc.createElement('b');
        this.cls = doc.createElement('a');
        this.cls.className = this.defaults.clsClassName;
        this.cls.href = 'javascript:;';
        this.sbj.appendChild(this.title);
        this.box.appendChild(this.cls);
        this.box.appendChild(this.sbj);
        this.cont = doc.createElement('div');
        this.cont.className = 'dia-cont';
        this.box.appendChild(this.cont);
        doc.body.appendChild(this.mask);
        doc.body.appendChild(this.box);

        !this.defaults.showTitle ? this.sbj.style.display = 'none' : void (0);
        !this.defaults.showClose ? this.cls.style.display = 'none' : void (0);
        this.cls.innerHTML = '×';
        this.title.innerHTML = this.defaults.title;

        if (this.defaults.url) {
            this.iframe = (/MSIE (6|7|8)/).test(navigator.userAgent) ? doc.createElement('<iframe>') : doc.createElement('iframe');
            this.iframe.style.width = '100%';
            this.iframe.style.height = parseInt(height) ? parseInt(height) - 70 + 'px' : height;
            this.iframe.style.border = 0;
            this.iframe.src = this.defaults.url;
            this.cont.appendChild(this.iframe);
        } else if (typeof this.defaults.content === 'string') {
            this.cont.innerHTML = this.defaults.content;
        } else {
            this.contParentNode = this.defaults.content.parentNode;
            this.cont.appendChild(this.defaults.content);
        }

        if (this.defaults.maskClick) {
            this.mask.onclick = function () {
                that.close();
            };
        }

        if (this.defaults.autoShow) { this.open(); }
        this.cls.onclick = function () { that.close(); };
        this.defaults.onInit(this);
    };

    Dialog.prototype.open = function () {
        var that = this;
        var $box = this.box;
        var $mask = this.mask;

        that.defaults.showMask && ($mask.style.display = 'block');
        $box.style.display = 'block';

        setTimeout(function () {
            $mask.style.opacity = 1;
            $box.style.opacity = 1;
            FD.supportTransform() ? $box.style.top = '50%' : setBoxPos($box, $mask);
            that.defaults.onOpened(that);
        }, 100);

        setTimeout(function () {
            $html = $html || doc.documentElement;
            $body = $body || doc.body;
            $html.style.overflow = 'hidden';
            $body.style.overflow = 'hidden';
        }, 300);
    };

    Dialog.prototype.close = function () {
        var that = this;
        that.defaults.onCloseBefore(that);
        that.mask.style.opacity = 0;
        that.box.style.opacity = 0;
        that.box.style.top = '-50%';

        setTimeout(function () {
            that.mask.style.display = 'none';
            that.box.style.display = 'none';
            that.defaults.onCloseAfter(that);
            doc.body.removeChild(that.mask);

            if (that.contParentNode) {
                that.contParentNode.appendChild(that.defaults.content);
            } else {
                doc.body.removeChild(that.box);
            }
        }, 200);

        setTimeout(function () {
            $html.style.overflow = 'auto';
            $body.style.overflow = 'auto';
        }, 300);
    };

    function extend(defaults, settings) {
        for (var key in settings) {
            if (defaults.hasOwnProperty(key)) {
                defaults[key] = settings[key];
            }
        }

        return defaults;
    }

    function setBoxPos($box, $mask) {
        var clientHeight = doc.documentElement.clientHeight || doc.body.clientHeight;
        var offsetHeight = Math.max(doc.documentElement.offsetHeight, doc.body.offsetHeight, clientHeight);
        var scrollTop = 0;

        setTimeout(function () {
            $box.style.left = (doc.body.clientWidth - $box.clientWidth) / 2 + 'px';

            if (FD.supportFixed()) {
                $box.style.top = (clientHeight - $box.clientHeight) / 2 + 'px';
            } else {
                scrollTop = doc.documentElement.scrollTop || doc.body.scrollTop;
                $box.style.top = (clientHeight - $box.clientHeight) / 2 + scrollTop + 'px';
            }
        }, 100);

        $mask.style.height = offsetHeight + 'px';
        $mask.style.background = '#333';
        $mask.className = $mask.className + ' dialog-mask-filter';
    }

    FD.Dialog = Dialog;
})(window, document);

//右侧工具条插件
(function (doc, win) {
    var docRoot = doc.compatMode === 'CSS1Compat' ? doc.documentElement : doc.body;

    function SideTools(options) {
        if (!(this instanceof SideTools)) { return new SideTools(options); }

        this.defaults = {
            content: '', //盒子的html内容
            bottom: 150, //距离底部的距离
            layoutWidth: 990, //页面宽度
            offset: 20, //向左偏移的距离
            className: 'side-fixed-tools', //盒子的样式名称
            callback: function () { } //初始化之后的回调函数
        };

        this.defaults = FD.extend(this.defaults, options);
        this.box = doc.createElement('div');
        this._init();
    }

    SideTools.prototype._init = function () {
        this.box.className = this.defaults.className;
        this.box.style.visibility = 'hidden';
        this.box.innerHTML = this.defaults.content;

        if (FD.supportFixed()) {
            this.box.style.position = 'fixed';
            this.box.style.bottom = parseInt(this.defaults.bottom) + 'px';
        } else {
            this.box.style.position = 'absolute';
            listenScroll(this);
        }

        setPositionLeft(this);
        doc.body.appendChild(this.box);
        display(this.box);
        listenResize(this);
        this.defaults.callback(this);
    };

    SideTools.prototype.close = function () {
        this.box && doc.body.removeChild(this.box);
    };

    function setPositionLeft(that) {
        var left = (docRoot.offsetWidth / 2) + (that.defaults.layoutWidth / 2) + that.defaults.offset;
        that.box.style.left = left + 'px';
    }

    function listenScroll(that) { //ie6
        var fn = win.onscroll;

        win.onscroll = FD.throttle(function () {
            var scrollTop = doc.documentElement.scrollTop || doc.body.scrollTop;
            var clientHeight = docRoot.clientHeight;

            that.box.style.top = scrollTop + clientHeight - that.box.clientHeight - that.defaults.bottom + 'px';

            if (typeof fn === 'function') {
                fn();
            }
        }, 300, 500);
    }

    function listenResize(that) {
        var fn = win.onresize;

        win.onresize = FD.throttle(function () {
            setPositionLeft(that);

            if (typeof fn === 'function') {
                fn();
            }
        }, 300, 500);
    }

    function display(elem) {
        var fn = win.onscroll;

        win.onscroll = FD.throttle(function () {
            var scrollTop = doc.documentElement.scrollTop || doc.body.scrollTop;
            var clientHeight = win.innerHeight || docRoot.clientHeight;

            if (scrollTop > clientHeight / 2) {
                elem.style.visibility = 'visible';
                elem.style.opacity = 1;
            } else {
                elem.style.opacity = 0;
                elem.style.visibility = 'hidden';
            }

            if (typeof fn === 'function') {
                fn();
            }
        }, 300, 500);
    }

    FD.SideTools = SideTools;
})(document, window);

(function() {
    $(function () {
        // 底部 yyk 推荐医院/推荐文章
        function inityykRecommend() {
            var renderHos = function (ele, data) {
                for (var i = 0; i < data.length; i++) {
                    ele.append('<li><a href="' + data[i].PcUrl + '" target="_blank">' + data[i].HospitalName + '</a></li>');
                }
            };
        
            var renderArticle = function (ele, data) {
                console.log(data);
                for (var i = 0; i < data.length; i++) {
                    ele.append('<li><a href="' + data[i].PcUrl + '" target="_blank">' + data[i].Title + '</a></li>');
                }
            };

            var renderDr = function (ele, data) {
                for (var i = 0; i < data.length; i++) {
                    ele.append('<li><a href="' + data[i].PcUrl + '" target="_blank">' + data[i].Name + '</a></li>');
                }
            };

            var getCont = function (path, callback) {
                var $wrap = null;
        
                switch (path) {
                    case 'GetHospitalList':
                        $wrap = $('.js-yyk-hos');
                    break;
                    case 'GetStateHospitalList':
                        $wrap = $('.js-yyk-hos-20241022');
                    break;
                    case 'GetStateDoctorList':
                        $wrap = $('.js-yyk-dr');
                    break;
                    case 'GetCustomArticleList':
                        $wrap = $('.js-yyk-article');
                    break;
                }

                if (!$wrap || !$wrap.length) {
                    return;
                }
    
                $.ajax({
                    url: 'https://kuapi.ap.familydoctor.com.cn/Hospital/' + path + '?Token=6532F73E301244D0ACD^A7C751*779A989',
                    type: 'GET',
                    success: function (res) {
                        if (!res.Bool) {
                            throw new Error('获取数据失败');
                        }
                        typeof callback === 'function' && callback && callback($wrap, res.Data);
                    },
                    error: function (err) {
                        throw new Error(err);
                    }
                });
            };

            getCont('GetHospitalList', renderHos);
            getCont('GetStateHospitalList', renderHos);
            getCont('GetStateDoctorList', renderDr);
            getCont('GetCustomArticleList', renderArticle);
        }

        // 底部 jbk 名院推荐/最新文章
        function initjbkRecommend() {
            var renderHos = function (ele, data) {
                for (var i = 0; i < data.length; i++) {
                    ele.append('<li><a href="' + data[i].PcUrl + '" target="_blank">' + data[i].HospitalName + '</a></li>');
                }
            };
        
            var renderArticle = function (ele, data) {
                for (var i = 0; i < data.length; i++) {
                    ele.append('<li><a href="' + data[i].PcUrl + '" target="_blank">' + data[i].Title + '</a></li>');
                }
            };
    
            var getCont = function (path, id, $item) {
                var $wrap = null;
                var param = null;
                var callback = null;

                switch (path) {
                    case 'FamousHospitalRecommend':
                        $wrap = $('.js-jbk-hos-famous');
                        param = 'AreaCode=';
                        callback = renderHos;
                        break;
                    case 'NewestArticle':
                        $wrap = $('.js-jbk-news-latest');
                        param = 'Category=';
                        callback = renderArticle;
                    break;
                }
    
                if (!$wrap || !$wrap.length) {
                    return;
                }

                if (!$item || !$item.length) {
                    $item = $wrap.find('.js-tab-item').eq(0);
                }
                
                $.ajax({
                    url: 'https://kuapi.ap.familydoctor.com.cn/FooterApi/' + path + '?' + param + id + '&Token=6532F73E301244D0ACD^A7C751*779A989',
                    type: 'GET',
                    success: function (res) {
                        if (!res.Bool) {
                            throw new Error('获取数据失败');
                        }
                        typeof callback === 'function' && callback && callback($item, res.Data);
                        $item.animate({ 'opacity': 1 }, 150);
                    },
                    error: function (err) {
                        throw new Error(err);
                    }
                });
            };

            var switchFn = function (ele) {
                if (!$(ele).length) {
                    return;
                }

                var $wrap = $(ele);
                var $tabs = $wrap.find('.js-tabs a');
                var $items = $wrap.find('.js-tab-item');
                var path = '';

                switch (ele) {
                    case '.js-jbk-hos-famous':
                        path = 'FamousHospitalRecommend';
                        break;
                    case '.js-jbk-news-latest':
                        path = 'NewestArticle';
                    break;
                }

                FD.initTabs(ele, 'click');
                $items.css({ 'min-height': '150px', 'opacity': 0 });
                $tabs.on('click', function () {
                    var $this = $(this);
                    var id = $this.attr('data-id');
                    var index = $this.index();

                    $this.addClass('on').siblings().removeClass('on');
                    $items.eq(index).removeClass('hide');
                    if ($items.eq(index).html() !== '' || $this.hasClass('isOver')) {
                        return;
                    }

                    getCont(path, id, $items.eq(index));
                    $this.addClass('isOver');
                });
            };
            
            switchFn('.js-jbk-hos-famous');
            switchFn('.js-jbk-news-latest');
            getCont('FamousHospitalRecommend', 2);
            getCont('NewestArticle', 1);
        }

        // 底部/侧栏 医院问答/最新资讯
        function getQnaOrNews(type, size, callback) {
            size > 50 && (size = 50);

            if (type === 0) {
                $.ajax({
                    url: 'https://kuapi.ap.familydoctor.com.cn/Hospital/CustomAskQuestion?Token=6532F73E301244D0ACD^A7C751*779A989&size=' + size,
                    type: 'GET',
                    success: function (res) {
                        if (!res.Bool) {
                            return;
                        }
                        callback(res.Data);
                    },
                    error: function (err) {
                        throw new Error(err);
                    }
                });
            }
            else if (type === 1) {
                $.ajax({
                    url: 'https://kuapi.ap.familydoctor.com.cn/Hospital/CustomArticle?Token=6532F73E301244D0ACD^A7C751*779A989&size=' + size,
                    type: 'GET',
                    success: function (res) {
                        if (!res.Bool) {
                            return;
                        }
                        callback(res.Data);
                    },
                    error: function (err) {
                        throw new Error(err);
                    }
                });
            }
        }
        
        function initjkktBottom() {
            var getTime = function(time, format) {
                var dateComponents = new Date(parseInt(time.slice(6, 19))).toLocaleDateString().split('/');
                dateComponents[1] = ('0' + dateComponents[1]).slice(-2);
                dateComponents[2] = ('0' + dateComponents[2]).slice(-2);
                return dateComponents.join(format);
            };
            var initCont = function(ele) {
                var $wrap = $(ele);

                if ($wrap.hasClass('js-jkkt-qna')) {
                    getQnaOrNews(0, 10, function (data) {
                        $.each(data, function(i, v) {
                            $wrap.append('<li><span class="iTitle"><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></span><span class="iDate">' + getTime(v.PublishDate,'-') + '</span></li>');
                        });
                    });
                }
                else if ($wrap.hasClass('js-jkkt-news')) {
                    getQnaOrNews(1, 10, function (data) {
                        $.each(data, function(i, v) {
                            $wrap.append('<li><span class="iTitle"><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></span><span class="iDate">' + getTime(v.PublishDate,'-') + '</span></li>');
                        });
                    });
                }
            };

            initCont('.js-jkkt-qna');
            initCont('.js-jkkt-news');
        }

        function initQnaOrNewsSide() {
            var initCont = function(ele) {
                var $wrap = $(ele);

                if ($wrap.hasClass('js-yyksidepics-qna2014')) {
                    getQnaOrNews(0, 5, function (data) {
                        $.each(data, function(i, v) {
                            if (i === 0) {
                                $wrap.append('<dl><dt><a href="' + v.PcUrl + '"title="' + v.Title + '" target="_blank"><img src="' + v.ImgSrc + '"></a></dt><dd><b><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></b><p><a href="' + v.PcUrl + '" target="_blank">[详情]</a></p></dd></dl>');

                                return;
                            }

                            $wrap.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
                else if ($wrap.hasClass('js-yyksidepics-news2014')) {
                    getQnaOrNews(1, 5, function (data) {
                        $.each(data, function(i, v) {
                            if (i === 0) {
                                $wrap.append('<dl><dt><a href="' + v.PcUrl + '"title="' + v.Title + '" target="_blank"><img src="' + v.ImgSrc + '"></a></dt><dd><b><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></b><p><a href="' + v.PcUrl + '" target="_blank">[详情]</a></p></dd></dl>');

                                return;
                            }

                            $wrap.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
                else if ($wrap.hasClass('js-yykside-news2016') || $wrap.hasClass('js-yykside-qna2016')) {
                    getQnaOrNews(1, 10, function (data) {
                        $wrap.append('<ul class="textList js-ul"></ul>');
                        var $ul = $wrap.find('.js-ul');
                        $.each(data, function(i, v) {
                            $ul.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
                else if ($wrap.hasClass('js-yyksidepics-qna2013')) {
                    getQnaOrNews(0, 9, function (data) {
                        var $ul = null;

                        $.each(data, function(i, v) {
                            if (i === 0) {
                                $wrap.append('<div class="imgText_100_100 clearfix"><div class="imgText_img"><a target="_blank" href="' + v.PcUrl + '"><img style="width:100px;height:100px;" src="' + v.ImgSrc + '" alt="' + v.Title + '"></a></div><div class="text"><h4><a target="_blank" href="' + v.PcUrl + '">' + v.Title + '</a></h4><p><a target="_blank" href="' + v.PcUrl + '">[详细]</a></p></div></div>');

                                $wrap.append('<ul class="textList js-ul"></ul>');
                                $ul = $wrap.find('.js-ul');
                                return;
                            }
                            
                            $ul.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
                else if ($wrap.hasClass('js-yykside-news2013')) {
                    getQnaOrNews(1, 40, function (data) {
                        $wrap.append('<ul class="js-ul"></ul>');
                        var $ul = $wrap.find('.js-ul');
                        $.each(data, function(i, v) {
                            $ul.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
            };

            initCont('.js-yykside-news2016');
            initCont('.js-yykside-qna2016');
            initCont('.js-yyksidepics-qna2014');
            initCont('.js-yyksidepics-news2014');
            initCont('.js-yyksidepics-qna2013');
            initCont('.js-yykside-news2013');
        }

        function initQnaOrNewsBottom() {
            var initCont = function(ele) {
                var $wrap = $(ele);

                if ($wrap.hasClass('js-yykbottom-qna2016')) {
                    getQnaOrNews(0, 12, function (data) {
                        $.each(data, function(i, v) {
                            $wrap.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
                else if ($wrap.hasClass('js-yykbottom-news2016')) {
                    getQnaOrNews(1, 12, function (data) {
                        $.each(data, function(i, v) {
                            $wrap.append('<li><a href="' + v.PcUrl + '" target="_blank">' + v.Title + '</a></li>');
                        });
                    });
                }
            };

            initCont('.js-yykbottom-qna2016');
            initCont('.js-yykbottom-news2016');
        }

        initjbkRecommend();
        inityykRecommend();
        initjkktBottom();
        initQnaOrNewsSide();
        initQnaOrNewsBottom();
    });
})();

if (!window.console) {
    window.console = { log: function () { } };
}
